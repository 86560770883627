import React from "react"
import styled from "styled-components"

export const TestimoniesNextArrow = props => {
  const { className, style, onClick } = props
  return (
    <TestiNext className={className} style={{ ...style }} onClick={onClick} />
  )
}

export const TestimoniesPrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <TestiPrev className={className} style={{ ...style }} onClick={onClick} />
  )
}

export const MediaPrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <MediaPrev className={className} style={{ ...style }} onClick={onClick} />
  )
}

export const MediaNextArrow = props => {
  const { className, style, onClick } = props
  return (
    <MediaNext
      className={className}
      style={{ ...style, right: "-10px" }}
      onClick={onClick}
    />
  )
}

const TestiNext = styled.div`
  right: 50px !important;
  @media (max-width: 768px){
    top: 400px;
  }
  ::before {
    background-color: #da649f;
    display: block;
    font-family: "Typefez-med";
    font-size: 20px;
    color: white !important;
    content: "→";
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    position: absolute;
    top: 50%;
    z-index: 2;
    border-radius: 50%;
    :hover {
      background-color: #e6e6e6;
      color: red !important;
    }
  }
`

const TestiPrev = styled.div`
  left: 480px;
  z-index: 90;
  @media (max-width: 1200px){
    left: 400px;
  }
  @media (max-width: 992px){
    left: 300px;
  }
  @media (max-width: 768px){
    left: 25px;
    top: 400px;
  }
  ::before {
    display: block;
    font-family: "Typefez-med";
    font-size: 20px;
    color: white !important;
    content: "←";
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #da649f;
    border: none;
    position: absolute;
    top: 50%;
    z-index: 99;
    border-radius: 50%;
    :hover {
      background-color: #e6e6e6;
      color: red !important;
    }
  }
`

const MediaNext = styled.div`
  ::before {
    font-family: "Typefez-med";
    font-size: 20px;
    color: white !important;
    content: "→";
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #da649f;
    border: none;
    position: absolute;
    top: calc(50% - 15px);
    z-index: 2;
    border-radius: 50%;
    :hover {
      background-color: #e6e6e6;
      color: black !important;
    }
  }
`

const MediaPrev = styled.div`
  ::before {
    font-family: "Typefez-med";
    font-size: 20px;
    color: white !important;
    content: "←";
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #da649f;
    border: none;
    position: absolute;
    top: calc(50% - 15px);
    left: 5px;
    z-index: 2;
    border-radius: 50%;
    :hover {
      background-color: #e6e6e6;
      color: black !important;
    }
  }
`
